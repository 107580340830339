<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Using the enthalpies of formation and balanced equation given here, answer each of the
        questions below:
      </p>

      <p class="mb-4 pl-10">
        <stemble-latex
          :content="`$${coefA}\\ce{A(g) + }${coefB}\\ce{B(g) -> }\\,${coefC}\\ce{C(g) + }${coefD}\\ce{D(g)}$`"
        />
      </p>

      <v-simple-table style="width: 275px" class="mb-5">
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}^{\circ}_{\text{f}}(\text{A(g)) =}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="HfA" unit="\text{kJ/mol}" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}^{\circ}_{\text{f}}(\text{B(g)) =}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="HfB" unit="\text{kJ/mol}" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}^{\circ}_{\text{f}}(\text{C(g)) =}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="HfC" unit="\text{kJ/mol}" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}^{\circ}_{\text{f}}(\text{D(g)) =}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="HfD" unit="\text{kJ/mol}" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-4">
        a) Determine the enthalpy of reaction,
        <stemble-latex content="$\Delta\text{H}_{\text{r}}$" />
        , for this process.
      </p>

      <calculation-input
        v-model="inputs.Hrxn"
        dense
        class="mb-4"
        prepend-text="$\Delta\text{H}^{\circ}_{\text{r}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-4">b) Is this an endothermic or exothermic process?</p>

      <v-radio-group v-model="inputs.exoendo" class="pl-10" row :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block mr-8"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question263',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Hrxn: null,
        exoendo: null,
      },
      options: [
        {expression: '$\\text{Exothermic}$', value: 'exo'},
        {expression: '$\\text{Endothermic}$', value: 'endo'},
      ],
    };
  },
  computed: {
    coefA() {
      return this.taskState.getValueBySymbol('coefA').content.number;
    },
    coefB() {
      return this.taskState.getValueBySymbol('coefB').content.number;
    },
    coefC() {
      return this.taskState.getValueBySymbol('coefC').content.number;
    },
    coefD() {
      return this.taskState.getValueBySymbol('coefD').content.number;
    },
    HfA() {
      return this.taskState.getValueBySymbol('HfA').content;
    },
    HfB() {
      return this.taskState.getValueBySymbol('HfB').content;
    },
    HfC() {
      return this.taskState.getValueBySymbol('HfC').content;
    },
    HfD() {
      return this.taskState.getValueBySymbol('HfD').content;
    },
  },
};
</script>
